const Layout = () => import('@/views/auth/Layout');
const Login = () => import('@/views/auth/Login.vue');
const ForgotPassword = () => import('@/views/auth/ForgotPassword.vue');
const ResetPassword = () => import('@/views/auth/ResetPassword.vue');
const ActivateAccount = () => import('@/views/auth/ActivateAccount.vue');
const ResetPasswordSuccessMobile = () => import('@/views/auth/ResetPasswordSuccessMobile.vue');

export const ROUTE_NAMES_AUTH = Object.freeze({
  AUTH: 'Auth',
  LOGIN: 'AuthLogin',
  FORGOT_PASSWORD: 'AuthForgotPassword',
  RESET_PASSWORD: 'AuthResetPassword',
  REGISTER: 'AuthRegister',
  RESET_PASSWORD_SUCCESS_MOBILE: 'ResetPasswordSuccess'
});

export const auth = {
  path: '/auth',
  name: ROUTE_NAMES_AUTH.AUTH,
  component: Layout,
  children: [
    {
      path: 'login',
      name: ROUTE_NAMES_AUTH.LOGIN,
      meta: {
        // guard: AutoLoginGuard
      },
      component: Login
    },
    {
      path: 'forgot-password',
      name: ROUTE_NAMES_AUTH.FORGOT_PASSWORD,
      meta: {},
      component: ForgotPassword
    },
    {
      path: 'reset-password/:userId/:resetToken/:roleId',
      name: ROUTE_NAMES_AUTH.RESET_PASSWORD,
      meta: {},
      component: ResetPassword
    },
    {
      path: 'activate-professional-account/:userId/:activationToken',
      name: ROUTE_NAMES_AUTH.REGISTER,
      meta: {},
      component: ActivateAccount
    },
    {
      path: 'reset-password-success',
      name: ROUTE_NAMES_AUTH.RESET_PASSWORD_SUCCESS_MOBILE,
      meta: {},
      component: ResetPasswordSuccessMobile
    },
  ]
};
