<template>
  <div class="user-info-wrapper">

    <!-- Mask - Used to automatically close the dropdown-->
    <div v-if="isDropdownShown" @click="toggleDropdown" class="status-mask"></div>

    <div v-if="user" @click="toggleDropdown()" class="logged-in-user-info">
      <div class="left-section">
        <div class="welcome">Welkom, </div>
        <div class="username">{{user.firstName}} {{user.lastName}}</div>
      </div>
      <div class="middle-section">
        <div class="badge-initial">
          {{firstCharacterOfName}}
        </div>
      </div>
      <div class="arrow">
        <font-awesome-icon v-if="isDropdownShown" icon="fa-angle-up" class=" faAngleUp dropdown-icon"/>
        <font-awesome-icon v-else icon="fa-angle-down" class=" faAngleDown dropdown-icon"/>
      </div>
    </div>
    <div v-if="isDropdownShown" class="dropdown-wrapper">
      <div v-for="option in dropdownOptions" 
           :key="option.id" 
           @click="selectOption(option)"
           class="option-item">
        <div class="item">
          <img v-if="option.icon" :src="require(`@/assets/icons/${option?.icon}.svg`)" class="icon" alt="">
          <span>{{option.name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from 'vue'
import {ROUTE_NAMES_ROOT} from '@/router/modules'
import {useRouter} from 'vue-router';
export default {
  name: "ProfileDropdown",
  components: {

  },
  props: {
    user: {
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const firstCharacterOfName = computed(() => props.user.firstName.charAt(0));

    const isDropdownShown = ref(false);

    function toggleDropdown() {
      isDropdownShown.value = !isDropdownShown.value
    }

    const dropdownOptions = ref([
      {
        id: 1,
        name: 'Instellingen',
        icon: 'ic_settings',
        route: ''
      },
      {
        id: 2,
        name: 'Log uit',
        icon: 'ic_logout',
        route: ROUTE_NAMES_ROOT.LOGOUT
      }
    ])

    function selectOption(option) {
      redirectUserTo(option.route)
    }

    function redirectUserTo(route){
      router.push({name: route})
    }

    return {
      firstCharacterOfName,
      isDropdownShown,
      toggleDropdown,
      dropdownOptions,
      selectOption
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

.logged-in-user-info {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 20px;
  margin-right: 16px;

  .left-section {
    text-align: right;
    .welcome {
      color: #4E5860;
    }

    .username {
      font-weight: var(--font-bold);
    }
  }

  .middle-section {
    .badge-initial {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: white;
      font-size: 32px;
      font-weight: var(--font-bold);
      background-color: #2da1be;
    }
  }
}

.user-info-wrapper {
  position: relative;
  cursor: pointer;
}

.dropdown-wrapper {
  @include position-elevation(absolute, $left: rem(-50), $elevation: 20);
  margin-top: rem(22);
  border: solid rem(1) var(--gray-light-c);
  background-color: var(--white-main);
  padding: rem(16);
  width: rem(200);
  border-radius: rem(3);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70,70,70,0.15);

  .option-item {
    &:not(:last-child) {
      margin-bottom: rem(20);
    }

    .item {
      display: flex;
      align-items: center;
      cursor: pointer;
      
      .icon {
        width: rem(20);
        margin-right: rem(10);
      }
    }
  }
}

// Mask
.status-mask {
  @include position-elevation(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0, $elevation: 10);
  @include on-hover {
    cursor: default;
  }
  background-color: transparent;
}
</style>