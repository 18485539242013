import ProfessionalGuard from '@/router/guards/professional';
import AuthGuard from '@/router/guards/auth';

const ProfessionalLayout = () => import('@/views/professional/Layout');
const Clients = () => import('@/views/professional/Clients.vue');
const ArchivedClients = () => import('@/views/professional/ArchivedClients.vue');
const CreateClient = () => import('@/views/professional/CreateClient.vue');
const ClientDetails = () => import('@/views/professional/ClientDetails.vue');

export const ROUTE_NAMES_PROFESSIONAL = Object.freeze({
  CLIENTS_OVERVIEW: 'ClientsOverview',
  CREATE_CLIENT: 'CreateClient',
  CLIENT_DETAILS: 'ClientDetails',
  ARCHIVED_CLIENTS: 'ArchivedClients',
});

export const professional = {
  path: '/professional',
  meta: {
    guard: AuthGuard
  },
  component: ProfessionalLayout,
  children: [
    {
      path: 'clients',
      name: ROUTE_NAMES_PROFESSIONAL.CLIENTS_OVERVIEW,
      component: Clients,
      meta: {
        guard: ProfessionalGuard,
        pageTitle: 'Cliënten',
      }
    },
    {
      path: 'clients/archived',
      name: ROUTE_NAMES_PROFESSIONAL.ARCHIVED_CLIENTS,
      component: ArchivedClients,
      meta: {
        guard: ProfessionalGuard,
        pageTitle: 'Gearchiveerde cliënten',
        breadcrumb: [
          {
            name: ROUTE_NAMES_PROFESSIONAL.CLIENTS_OVERVIEW,
            text: 'Cliënten'
          },
          {
            name: ROUTE_NAMES_PROFESSIONAL.ARCHIVED_CLIENTS,
            text: 'Gearchiveerde cliënten'
          },
        ],
      },
    },
    {
      path: 'create-client',
      name: ROUTE_NAMES_PROFESSIONAL.CREATE_CLIENT,
      component: CreateClient,
      meta: {
        guard: ProfessionalGuard,
        pageTitle: 'Cliënten uitnodigen',
        breadcrumb: [
          {
            name: ROUTE_NAMES_PROFESSIONAL.CLIENTS_OVERVIEW,
            text: 'Cliënten'
          },
          {
            name: ROUTE_NAMES_PROFESSIONAL.CREATE_CLIENT,
            text: 'Cliënten uitnodigen'
          },
        ],
      },
    },
    {
      path: 'client-details/:clientId',
      name: ROUTE_NAMES_PROFESSIONAL.CLIENT_DETAILS,
      component: ClientDetails,
      meta: {
        guard: ProfessionalGuard,
        breadcrumb: [
          {
            name: ROUTE_NAMES_PROFESSIONAL.CLIENTS_OVERVIEW,
            text: 'Cliënten'
          },
          {
            name: ROUTE_NAMES_PROFESSIONAL.CLIENT_DETAILS,
            textWithName: (name = "") => `${name}`
          },
        ],
      },
    },
  ]
}
