<template>
  <div id="app">
    <router-view/>

    <!-- Loading indicator -->
    <LoadingSpinner />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/elements/LoadingSpinner";

export default {
  components: {
    LoadingSpinner
  }
}
</script>

<style lang="scss">
@import "assets/css/layout.shared";
</style>
