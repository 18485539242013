<template>
  <div v-if="isLoading" class="spinner">
    <div class="dots">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import {useRootStore} from '@/store/'
import {computed} from 'vue'

export default {
  name: "LoadingSpinner",
  setup() {
    const store = useRootStore();
    const isLoading = computed(() => store.isPageLoading);

    return {
      isLoading
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";
@import "../../assets/css/base.variables";

.spinner {
  @include position-elevation(fixed, $top: 50%, $left: 50%, $elevation: 1150);
  height: rem(50);
  transform: translate(-50%, -50%);
  width: rem(50);

  .dots {
    display: inline-block;
    position: relative;
    width: rem(80);
    height: rem(80);
  }

  .dots div {
    animation: rotate-element 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: rem(40) rem(40);
  }

  .dots div:after {
    content: "";
    display: block;
    position: absolute;
    width: rem(7);
    height: rem(7);
    border-radius: 50%;
    background: var(--blue-main);
    margin: rem(-4) 0 0 rem(-4);
  }

  .dots div:nth-child(1) {
    animation-delay: -0.036s;
  }

  .dots div:nth-child(1):after {
    top: rem(63);
    left: rem(63);
  }

  .dots div:nth-child(2) {
    animation-delay: -0.072s;
  }

  .dots div:nth-child(2):after {
    top: rem(68);
    left: rem(56);
  }

  .dots div:nth-child(3) {
    animation-delay: -0.108s;
  }

  .dots div:nth-child(3):after {
    top: rem(71);
    left: rem(48);
  }

  .dots div:nth-child(4) {
    animation-delay: -0.144s;
  }

  .dots div:nth-child(4):after {
    top: rem(72);
    left: rem(40);
  }

  .dots div:nth-child(5) {
    animation-delay: -0.18s;
  }

  .dots div:nth-child(5):after {
    top: rem(71);
    left: rem(32);
  }

  @keyframes rotate-element {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}
</style>
