export const getters = {
  /** Loading Spinner **/
  isPageLoading: (state) => state.isLoading,
  
  /** Pagination **/
  getPaginationData: (state) => state.paginationData,

  /** Modal **/
  getModalOptions: (state) => state.modalContent,
  getIsModalShown: (state) => state.isModalShown,
  getActiveModal: (state) => state.activeModal,

  /** Toast **/
  getToastOptions: (state) => state.toastOptions,

  getApiError: (state) => state.apiError,

  getBreadcrumbName: (state) => state.breadcrumbName
}
