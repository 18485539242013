import AuthGuard from '@/router/guards/auth';
import AdminGuard from '@/router/guards/admin';

const AdminLayout = () => import('../../views/admin/Layout.vue')
const Users = () => import('../../views/admin/users/Users.vue')
const UserDetails = () => import('@/views/admin/users/UserDetails.vue')
const CreateProfessional = () => import('../../views/admin/users/CreateProfessional.vue')
const EditProfessional = () => import('../../views/admin/users/EditProfessional.vue')
const SendResetLink = () => import('@/views/admin/users/SendResetLink.vue')

export const ROUTE_NAMES_ADMIN = Object.freeze({
  USERS: 'AdminUsers',
  USER_DETAILS: 'UserDetails',
  USERS_OVERVIEW: 'UsersOverview',
  CREATE_PROFESSIONAL: 'CreateProfessional',
  EDIT_PROFESSIONAL: 'EditProfessional',
  SEND_RESET_LINK: 'SendResetLink'
})

export const admin = {
  path: '/admin',
  meta: {
    guard: AuthGuard
  },
  component: AdminLayout,
  children: [
    {
      path: 'users',
      name: ROUTE_NAMES_ADMIN.USERS_OVERVIEW,
      component: Users,
      meta: {
        guard: AdminGuard,
        pageTitle: 'Gebruikers',
        pageButton: ROUTE_NAMES_ADMIN.CREATE_PROFESSIONAL
      }
    },
    {
      path: 'create-user',
      name: ROUTE_NAMES_ADMIN.CREATE_PROFESSIONAL,
      component: CreateProfessional,
      meta: {
        guard: AdminGuard,
        pageTitle: 'Professional account toevoegen',
        breadcrumb: [
          {
            name: ROUTE_NAMES_ADMIN.USERS_OVERVIEW,
            text: 'Gebruikers'
          },
          {
            name: ROUTE_NAMES_ADMIN.CREATE_PROFESSIONAL,
            text: 'Professional account toevoegen'
          },
        ],
      }
    },
    {
      path: 'user-details/:userId',
      name: ROUTE_NAMES_ADMIN.USER_DETAILS,
      component: UserDetails,
      meta: {
        guard: AdminGuard,
        breadcrumb: [
          {
            name: ROUTE_NAMES_ADMIN.USERS_OVERVIEW,
            text: 'Gebruikers'
          },
          {
            name: ROUTE_NAMES_ADMIN.USER_DETAILS,
            textWithName: (name = "") => `${name}`
          },
        ],
      }
    },
    {
      path: 'edit/user-details/:userId',
      name: ROUTE_NAMES_ADMIN.EDIT_PROFESSIONAL,
      component: EditProfessional,
      meta: {
        guard: AdminGuard,
        pageTitle: 'Accountgegevens bewerken',
        breadcrumb: [
          {
            name: ROUTE_NAMES_ADMIN.USERS_OVERVIEW,
            text: 'Gebruikers'
          },
          {
            name: ROUTE_NAMES_ADMIN.USER_DETAILS,
            textWithName: (name = "") => `${name}`
          },
          {
            name: ROUTE_NAMES_ADMIN.EDIT_PROFESSIONAL,
            text: 'Accountgegevens bewerken'
          },
        ],
      }
    },
    {
      path: 'edit/user-details/:userId/send-reset-link',
      name: ROUTE_NAMES_ADMIN.SEND_RESET_LINK,
      component: SendResetLink,
      meta: {
        guard: AdminGuard,
        pageTitle: 'Wachtwoord opnieuw instellen',
        breadcrumb: [
          {
            name: ROUTE_NAMES_ADMIN.USERS_OVERVIEW,
            text: 'Gebruikers'
          },
          {
            name: ROUTE_NAMES_ADMIN.USER_DETAILS,
            textWithName: (name = "") => `${name}`
          },
          {
            name: ROUTE_NAMES_ADMIN.EDIT_PROFESSIONAL,
            text: 'Wachtwoord opnieuw instellen'
          },
        ],
      }
    },
  ]
}
