export const ERROR_CODES = {
  DEFAULT: 'default',
  INVALID_CREDENTIALS: 'invalid_credentials',
  CONFLICT_ORGANISATION: 'conflict_organisation_exists',
  INVALID_2FA: 'invalid_2fa',
  USER_NOT_FOUND: 'not_found_user_not_found',
  CONFLICT_EMAIL: 'conflict_email_already_in_use',
}

export default {
  en: {
    default: "There is an error, please try again later.",
    invalid_grant: "Invalid username and password combination.",
    conflict_coachtip_announcement_exists: "There's already a coach tip scheduled at this time stamp."
  },
  nl: {
    default: "Er is een fout, probeer het later opnieuw.",
    invalid_credentials: "De combinatie van e-mail en wachtwoord is onjuist",
    conflict_organisation_exists: "Er bestaat al een organisatie met dezelfde naam",
    invalid_2fa: "Foute code. Probeer het opnieuw",
    not_found_user_not_found: "Ongeldig e-mailadres",
    conflict_email_already_in_use: 'Dit e-mailadres wordt al gebruikt.',
  }
}

