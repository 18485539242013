/** routes **/
import {auth} from '@/router/modules/auth'
import {admin} from '@/router/modules/admin'
import {professional} from '@/router/modules/professional'
import NotFound from '@/views/NotFound'
import Logout from "@/views/Logout";
import TermsAndConditions from '@/views/TermsAndConditions'
import Privacy from '@/views/Privacy'

export const ROUTE_NAMES_ROOT = Object.freeze({
  LOGOUT: 'Logout',
  NOT_FOUND: 'Not Found',
  TERMS: 'termsAndConditions',
  PRIVACY: 'Privacy'
});

/** routes without layout **/
export const root = [
  {
    path: '/logout',
    name: ROUTE_NAMES_ROOT.LOGOUT,
    component: Logout
  },
  {
    // not found
    path: '/not-found',
    name: ROUTE_NAMES_ROOT.NOT_FOUND,
    component: NotFound
  },
  {
    // not found
    path: '/terms',
    name: ROUTE_NAMES_ROOT.TERMS,
    component: TermsAndConditions
  },
  {
    // not found
    path: '/privacy',
    name: ROUTE_NAMES_ROOT.PRIVACY,
    component: Privacy
  },
]

export default root.concat([auth, admin, professional]);