import PaginationData from '@/models/PaginationData'
import apiErrorHandler from "@/utils/handlers/errorHandler/ApiErrorHandler";

export const actions = {
  setLoading(isLoading) {
    this.isLoading = isLoading;
  },
  
  setPaginationData(data) {
    this.paginationData = PaginationData.parseDataFromObject(data);
  },

  setModalOptions({modalContent, activeModal}) {
    this.modalContent = modalContent;
    this.activeModal = activeModal;
    this.isModalShown = true;
  },

  resetModalOptions() {
    this.modalContent = null;
    this.isModalShown = false;
    this.activeModal = null;
  },

  setActiveModal(activeModal) {
    this.activeModal = activeModal;
  },

  setToastOptions(data) {
    this.toastOptions = data;
  },
  resetToastOptions() {
    this.toastOptions = null;
  },

  setApiError(error){
    this.apiError = error ? apiErrorHandler.getErrorObject(error.response.data) : '';
  },

  setBreadcrumbName(name) {
    this.breadcrumbName = name;
  }
}
