import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import {createPinia} from "pinia";
import '@/setup/font-awesome';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import apiHandler from "./utils/handlers/ApiHandler";

/** Set up the api interceptors **/
apiHandler.setInterceptors();

const app = createApp(App);
app.use(router).use(createPinia()).component("font-awesome-icon", FontAwesomeIcon).mount('#app');
