import {createRouter, createWebHistory} from 'vue-router';
import modules from '@/router/modules';
import { ROUTE_NAMES_AUTH } from './modules/auth';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: modules,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return {top: 0};
  },
});

router.beforeEach(async (to, from, next) => {
  // User enters an invalid URL.
  if (to.matched.length === 0 || to.path === '/') {
    return next({name: ROUTE_NAMES_AUTH.LOGIN});
  }

  // User enters a valid URL.
  for (const route of to.matched) {
    if (route.meta && route.meta.guard) {
      /** Let each guard handle route access **/
      const result = await route.meta.guard.handleRouteEnter();
      if (result !== true) {
        return next({name: result});
      }
    }
  }
  addTitle(to);
  return next();
});

function addTitle(to) {
  if (to.meta.pageTitle) {
    document.title = to.meta.pageTitle;
  }
}

export default router;
