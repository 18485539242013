import LocalDataHandler from '@/utils/handlers/LocalDataHandler'
import BaseGuard from "@/router/guards/base";
import {USER_ROLES} from "@/models/UserRole";


class ProfessionalGuard extends BaseGuard {
  constructor() {
    super();
    this.typeAllowed = USER_ROLES.PROFESSIONAL;
  }

  async handleRouteEnter() {
    if (!LocalDataHandler.getAccessToken()) {
      return this.refreshUserProfile().then(response => {
        if (response) {
          return this.checkUserType() ? true : this.routeOnFailure;
        }
        /** keep in mind that the catch is handled in base guard (base.js) **/
        return this.routeOnFailure;
      });
    }
    return new Promise(resolve => {
      this.checkUserType() ? resolve(true) : resolve(this.routeOnFailure);
    });
  }

  checkUserType() {
    const activeUser = parseInt(LocalDataHandler.getUserType());
    return activeUser === this.typeAllowed;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ProfessionalGuard();
    }
    return this.instance;
  }
}

export default ProfessionalGuard.getInstance();
