import errors from './errors';

const DEFAULT_LANG = 'nl';

class ApiErrorHandler {
  constructor() {
    this.errors = errors;
    this.selectedErrors = errors[DEFAULT_LANG];
  }

  setLanguage(lang = DEFAULT_LANG) {
    this.selectedErrors = this.errors[lang] ? this.errors[lang] : this.errors[DEFAULT_LANG];
  }

  getErrorText(errorObj) {
    const errorKey = errorObj.error;
    return this.selectedErrors[errorKey] ? this.selectedErrors[errorKey] : this.selectedErrors.default;
  }

  getErrorObject(errorObj) {
    const errorKey = errorObj.error
    return {errorCode: errorKey, errorMessage: this.selectedErrors[errorKey]};
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ApiErrorHandler();
    }
    return this.instance;
  }
}

export default ApiErrorHandler.getInstance();
