<template>
  <div>
    <Header/>
    <div class="page-wrapper">
      <h1 class="page-title">Privacybeleid</h1>
    </div>
  </div>
</template>

<script>
import Header from "@/components/partials/Header";

export default {
  name: 'TermsAndConditions',
  components: {
    Header
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.page-wrapper {
  margin-top: var(--header-height);
  margin-bottom: rem(100);

  .terms-text {
    line-height: 1.2;
  }
}
</style>