<template>
  <router-view></router-view>
</template>

<script>
  import {useAuthStore} from '@/store/auth'
  import {useRouter} from 'vue-router'
  import { ROUTE_NAMES_AUTH } from '@/router/modules/auth';

  export default {
    name: 'Logout',
    setup() {
      const store = useAuthStore();
      const router = useRouter();

      store.logout();
      router.push({name: ROUTE_NAMES_AUTH.LOGIN});
    }
  }
</script>

<style scoped lang="scss">
</style>
