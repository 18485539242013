<template>
  <div>
    <Header/>
    <div class="page-wrapper">
      <h1 class="page-title">Gebruikersvoorwaarden</h1>
      <p class="terms-text">
        Kijk voor de algemene voorwaarden onderaan deze pagina.
        <br><br>
        Voor het gebruik van de Diensten van Therapieland gelden onderstaande gebruiksvoorwaarden. De Diensten wordt aangeboden door Therapieland B.V. gevestigd aan Nieuwendammerdijk 528 D te (1023BX) Amsterdam en ingeschreven bij de Kamer van Koophandel onder registratienummer 56516622 (hierna: “Therapieland”).
      </p>
      <h2>Definities</h2>
      <p>De met hoofdletter geschreven begrippen in deze gebruiksvoorwaarden hebben (zowel in enkelvoud als in meervoud) de onderstaande betekenis:</p>
        <ol>
          <li>Account: het persoonlijke account dat door Therapieland aan u ter beschikking wordt gesteld met als doel de afgenomen Diensten te kunnen gebruiken.</li>
          
          <li>Diensten: alle diensten die Therapieland op grond van de Overeenkomst aan u levert, waaronder begrepen kan zijn: het beschikbaar stellen van het Webportal waarmee de online zelfhulpprogramma’s worden aangeboden.</li>
          <li>Gebruiksvoorwaarden: de onderhavige voorwaarden.</li>
          <li>Intellectuele Eigendomsrechten: alle rechten van intellectuele eigendom en daarmee verwante rechten, waaronder in ieder geval auteursrechten, databankrechten, rechten op domeinnamen, handelsnaamrechten, rechten op knowhow, merkenrechten, modelrechten, naburige rechten en octrooirechten.</li>
          <li>Kantooruren: de tijd van 09:00 tot 17:00 uur van maandag tot en met vrijdag, met uitzondering van officiële Nederlandse feestdagen.</li>
          <li>Overeenkomst: de overeenkomst tussen u en Therapieland op grond waarvan u de Diensten kunt gebruiken en waarvan de onderhavige voorwaarden integraal onderdeel uitmaken.</li>
          <li>Partij(en): de partijen bij de Overeenkomst, u en Therapieland afzonderlijk of gezamenlijk.</li>
          <li>Schriftelijk: op papier gestelde, gedagtekende en ondertekende correspondentie. Onder schriftelijk valt naast beschreven papier ook e-mail en faxberichten, voor zover de afkomst en de integriteit van deze berichten voldoende vaststaat.</li>
          <li>Webportal: het webportal van Therapieland vanaf waar de Diensten wordt aangeboden.</li>
          <li>Website: de website van Therapieland, www.therapieland.nl en eventuele andere (sub-)domeinen.</li>
        </ol>

      <h2>Totstandkoming en de diensten</h2>
      <ol>
        <li>De Diensten bieden u de mogelijkheid om via een online platform gebruik te maken van zelfhulpprogramma’s. U bepaalt zelf hoe u de Diensten inzet en voor welke specifieke doelen.</li>
        <li>Om gebruik te kunnen maken van de Diensten, dient u zich te registreren. Nadat de registratie is afgerond, komt de Overeenkomst tussen Therapieland en u tot stand.</li>
        <li>Bij het gebruik van de Diensten kunnen er vragenlijsten worden afgenomen. Deze vragenlijsten dienen ervoor om uw voortgang en de symptomen in kaart te brengen. De vragenlijsten stellen geen diagnose. Raadpleeg voor een diagnose een gekwalificeerde arts, psycholoog of psychotherapeut. U kunt op basis van de vragenlijsten kiezen welke zelfhulpprogramma’s u volgt.</li>
        <li>De zelfhulpprogramma’s hebben ten doel u te ondersteunen bij (psychische) klachten. Therapieland behandelt niet en tussen u en Therapieland komt er derhalve geen behandelovereenkomst tot stand. U moet zich ervan bewust te zijn dat voor een behandeling een arts, psycholoog of psychotherapeut geraadpleegd moet worden.</li>
        <li>Het platform van Therapieland is te kwalificeren als een medisch hulpmiddel in de zin van Verordening (EU) 2017/745 van het Europees Parlement en de Raad van 5 april 2017 betreffende medische hulpmiddelen. Dat betekent dat Therapieland op grond van deze verordening onder andere:</li>
        <ol>
          <li>geen tekst, benamingen, merken, afbeeldingen en andere al dan niet figuratieve tekens zal gebruiken die u kunnen misleiden met betrekking tot het beoogde doeleinde, de veiligheid en de prestaties van het platform. Indien u het bepaalde in de vorige zin toch ervaart, kunt u contact opnemen met Therapieland via de contactgegevens in de Gebruiksvoorwaarden.</li>
          <li>uw klachten of meldingen over vermoedelijke incidenten in verband met het Webportal uiterst serieus neemt en een register bijhoudt van de eventuele klachten. Zie voorts artikel 9 van de Gebruiksvoorwaarden over de afhandeling van klachten.</li>
        </ol>
      </ol>

      <h2>Gebruiksregels en account</h2>
      <ol>
        <li>Het is verboden om de Diensten te gebruiken op een manier die in strijd is met deze voorwaarden of met toepasselijke wet- en regelgeving.</li>
        <li>Indien Therapieland constateert dat u deze voorwaarden of de wet overtreedt, of een klacht hierover ontvangt, mag Therapieland ingrijpen om de overtreding te beëindigen. Therapieland mag dan de toegang tot de betreffende informatie blokkeren.</li>
        <li>U dient de toegang tot het Account middels de gebruikersnaam en het wachtwoord af te schermen voor onbevoegden. In het bijzonder dient het wachtwoord strikt geheimgehouden te worden. Therapieland mag ervan uitgaan dat alles dat gebeurt vanaf uw Account, na aanmelding met gebruikersnaam en eventuele tweefactorauthenticatie, onder uw leiding en toezicht gebeurt.</li>
        <li>Therapieland is te allen tijde gerechtigd om aangifte te doen van geconstateerde strafbare feiten en zal zijn medewerking verlenen aan bevoegd gegeven bevelen. Daarnaast is Therapieland gerechtigd om uw naam, adres, IP-adres en andere identificerende gegevens van u af te geven aan een derde die klaagt dat u inbreuk maakt op diens rechten of deze voorwaarden, mits:</li>
        <ol>
          <li>het voldoende aannemelijk is dat de informatie, op zichzelf beschouwd, jegens de derde onrechtmatig en schadelijk is;</li>
          <li>de derde een reëel belang heeft bij de verkrijging van de gegevens;</li>
          <li>het aannemelijk is dat er in het concrete geval geen minder ingrijpende mogelijkheid is om de gegevens de achterhalen;</li>
          <li>de afweging van de betrokken belangen meebrengt dat het belang van de derde behoort te prevaleren.</li>
        </ol>
        <li>U bent verplicht om alle redelijke aanwijzingen van Therapieland te volgen die betrekking hebben op het gebruik van de Diensten.</li>
      </ol>

      <h2>Beveiliging en privacy</h2>
      <ol>
        <li>Therapieland zal zich maximaal inspannen om de Diensten te beveiligen tegen misbruik en ongeautoriseerde toegang tot uw gegevens. In het bijzonder is Therapieland ISO 27001 en NEN 7510 gecertificeerd.</li>
        <li>Bij gebruik van de Diensten worden uw persoonsgegevens verwerkt. Raadpleeg de privacyverklaring voor meer informatie.</li>
      </ol>

      <h2>Intellectuele eigendomsrechten</h2>
      <ol>
        <li>Alle Intellectuele Eigendomsrechten op de Diensten, alsmede alle informatie en afbeeldingen op de Website berusten bij Therapieland of diens licentiegevers.</li>
        <li>U krijgt van Therapieland de niet-exclusieve, niet-overdraagbare en niet-sublicentieerbare gebruiksrechten ten aanzien van de Diensten, alsmede alle informatie en afbeeldingen op de Website voor zover deze voortvloeien uit de Overeenkomst of welke anderszins schriftelijk zijn toegekend. De gebruiksrechten gelden voor de duur van de Overeenkomst.</li>
        <li>U bent niet gerechtigd om wijzigingen aan te brengen in de Diensten en u hebt geen recht op een kopie van de bronbestanden van de Diensten, behoudens de gevallen waarin dat wettelijk is toegestaan.</li>
        <li>Therapieland kan (technische) maatregelen nemen ter bescherming van de Diensten. Indien Therapieland dergelijke beveiligingsmaatregelen heeft genomen, is het u niet toegestaan om deze beveiliging te ontwijken of te verwijderen.</li>
        <li>Het is u niet toegestaan om enige aanduiding van Intellectuele Eigendomsrechten uit de Diensten te verwijderen of aan te passen. Het is tevens niet toegestaan om aanduidingen omtrent het vertrouwelijke karakter uit de Diensten te verwijderen.</li>
      </ol>

      <h2>Uw data</h2>
      <p>De data en gegevens die u invoert via de Diensten, zonder de tussenkomst van een behandelaar blijven uw eigendom. U verstrekt aan Therapieland een gebruiksrecht voor deze data en gegevens voor de duur van de Overeenkomst. U kunt dit gebruiksrecht beëindigen door (de Overeenkomst op te zeggen en daarbij) te verzoeken om de data en gegevens te verwijderen.</p>

      <h2>Beschikbaarheid, onderhoud, ondersteuning en wijziging</h2>
      <ol>
        <li>Therapieland streeft ernaar om de Diensten zoveel mogelijk beschikbaar te houden, maar garandeert geen ononderbroken beschikbaarheid.</li>
        <li>Therapieland heeft het recht de Diensten of een onderdeel daarvan te wijzigen en te onderhouden.</li>
        <li>Onderhoud en wijziging van de Diensten door Therapieland kan tot gevolg hebben dat de Dienst tijdelijk geheel of gedeeltelijk buiten gebruik is. Therapieland zal zich inspannen deze beperkingen in het gebruik tijdig aan te kondigen.</li>
        <li>Voor (technische) ondersteuning kunt u terecht bij de helpdesk van Therapieland. De helpdesk is bereikbaar tijdens Kantooruren via 020-771284 en info@therapieland.nl.</li>
        <li>Therapieland streeft ernaar om helpdeskverzoeken binnen een redelijke termijn af te handelen. De tijd die nodig is voor het oplossen van meldingen kan variëren.</li>
      </ol>

      <h2>Vergoeding en betalingsvoorwaarden</h2>
      <ol>
        <li>Voor het gebruik van de Diensten bent u een vergoeding verschuldigd. Betaling kan worden verricht volgens de betaalinstructies op de Website. Therapieland is gerechtigd om elektronisch te factureren.</li>
        <li>Voor alle facturen geldt een betalingstermijn van 30 dagen, tenzij de factuur een andere betalingstermijn vermeldt of Schriftelijk een andere termijn is overeengekomen.</li>
        <li>Alle door Therapieland genoemde prijzen zijn in euro’s en inclusief btw.</li>
      </ol>

      <h2>Klachtenprocedure</h2>
      <ol>
        <li>Therapieland neemt klachten en meldingen ten aanzien van de Diensten uiterst serieus. In geval van vragen, opmerkingen en klachten, kan contact worden opgenomen door middel van de daartoe bestemde contactmogelijkheden op de Website of via de contactgegevens die onderaan de Gebruiksvoorwaarden staan vermeld. U krijgt een ontvangstbevestiging van uw klacht.</li>
        <li>Op klachten over de Diensten zal Therapieland zo spoedig mogelijk, maar uiterlijk binnen veertien (14) dagen na ontvangst reageren. Raadpleeg voor meer informatie de klachtenprocedure op de Website.</li>
      </ol>

      <h2>Duur en einde</h2>
      <ol>
        <li>Tenzij anders overeengekomen wordt de Overeenkomst aangegaan voor een onbepaalde periode.</li>
        <li>De Overeenkomst kan ten alle tijde door u worden opgezegd.</li>
      </ol>

      <h2>Duur en einde</h2>
      <ol>
        <li>Tenzij anders overeengekomen wordt de Overeenkomst aangegaan voor een onbepaalde periode.</li>
        <li>De Overeenkomst kan ten alle tijde door u worden opgezegd.</li>
      </ol>

      <h2>Wijziging algemene voorwaarden</h2>
      <ol>
        <li>Therapieland heeft het recht om deze Gebruiksvoorwaarden te wijzigen of aan te vullen. Therapieland zal de wijziging of aanvulling ten minste 30 dagen vóór inwerkingtreding per e-mail bij u aankondigen.</li>
        <li>Als u de wijzigingen of aanvullingen niet wenst te accepteren, kunt u binnen 14 dagen na bekendmaking gemotiveerd bezwaar maken, waarna Therapieland de aanpassing of aanvulling zal heroverwegen. Als Therapieland besluit om vast te houden aan de wijziging of aanvulling, kunt u de Overeenkomst Schriftelijk opzeggen tegen de datum waarop de wijziging in werking treedt. Het voorgaande geldt niet voor wijzigingen op grond van de wet en wijzigingen van ondergeschikt belang.</li>
      </ol>

      <h2>Exit</h2>
      <ol>
        <li>Therapieland zal alle data en gegevens van u voor de duur van de Overeenkomst bewaren. Therapieland zal zich inspannen na het einde van de Overeenkomst op uw verzoek u een kopie te verstrekken van deze data en gegevens. Datamodellen worden niet door Therapieland meegeleverd.</li>
        <li>De kopie wordt verstrekt in een door Therapieland te bepalen gangbaar bestandsformaat.</li>
      </ol>

      <h2>Overige bepalingen</h2>
      <ol>
        <li>Op de Overeenkomst is Nederlands recht van toepassing, tenzij dwingend consumentenrecht anders voorschrijft.</li>
        <li>Therapieland is gerechtigd om zijn rechten en plichten die voortvloeien uit de Overeenkomst over te dragen aan een derde partij die Therapieland of zijn bedrijfsactiviteiten overneemt.</li>
        <li>U dient Therapieland onverwijld op de hoogte te stellen bij een wijziging in naam, (post)adres, e-mailadres, telefoonnummer en overige voor de uitvoering van de Overeenkomst relevante informatie en gegevens indien dit nodig is. </li>
      </ol>

      <h2>Vragen?</h2>
      <p>Als u vragen hebt, aarzel dan niet om contact met Therapieland op te nemen via <a href="mailto:info@therapieland.nl">info@therapieland.nl</a>. </p>
    </div>
  </div>
</template>

<script>
import Header from "@/components/partials/Header";

export default {
  name: 'TermsAndConditions',
  components: {
    Header
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.page-wrapper {
  margin-top: var(--header-height);
  margin-bottom: rem(100);

  .terms-text {
    line-height: 1.2;
  }
}
</style>