import {actions} from './actions';
import {getters} from './getters';
import {apiActions} from './apiActions';
import {defineStore} from "pinia";

const state = () => ({
  isLoading: false,
  
  /** Pagination **/
  paginationData: null,

  /** Modal **/
  modalContent: null,
  activeModal: null,
  isModalShown: false,

  /** Toast **/
  toastOptions: null,

  /** API Error **/
  apiError: '',

  /** Breadcrumb Name **/
  breadcrumbName: ''
});

export const useRootStore = defineStore('root', {
  state,
  getters,
  actions: {...actions, ...apiActions}
});