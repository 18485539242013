<template>
  <div class="header-wrapper">
    <div class="header-box">
      <div class="header-logo">
        <h1 class="logo-text">Krachtplan</h1>
      </div>

      <!-- Profile -->
      <ProfileDropdown v-if="loggedInUser" :user="loggedInUser"/>

      <!-- Help -->
      <div v-else class="header-help">
        <a href="http://support.therapieland.nl/" target="_blank" class="help-text">Hulp nodig?</a>
      </div>

    </div>
  </div>
</template>

<script>
import {computed} from 'vue'
import LocalDataHandler from "@/utils/handlers/LocalDataHandler";
import ProfileDropdown from '@/components/partials/ProfileDropdown.vue'

export default {
  name: 'Header',
  components: {
    ProfileDropdown
  },
  setup() {
    const loggedInUser = computed(() => LocalDataHandler.getUser())

    return {
      loggedInUser,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

.dropdown-wrapper {
  align-self: flex-start;
}

.header-box {
  @include position-elevation(fixed, $top: 0, $right: 0, $left: 0);
  align-items: center;
  background-color: white;
  border-bottom: rem(1) solid var(--gray-dark-a);
  display: flex;
  height: var(--header-height);
  justify-content: space-between;
  padding: 0 rem(40);
}

// Logo
.logo-text {
  font-size: rem(22);
}

// Help
.help-text {
  font-size: rem(16);
  color: #000;
  text-decoration: none;
}
</style>
