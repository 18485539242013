import Axios from 'axios'
import LocalDataHandler from './LocalDataHandler'
import { useRootStore } from '@/store'

const RESPONSE_TYPES = {
  STATUS_OK: 200,
  STATUS_CREATED: 201,
  STATUS_NO_CONTENT: 204,
  STATUS_UNAUTHORIZED: 401
}

const ROUTE_NAMES = Object.freeze({
  LOGOUT: 'Logout',
});

const TOKEN_SAVING_DELAY = 500;

class ApiHandler {
  constructor() {
    /** Create the api instance for auth APIs **/
    this.apiAuth = Axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/api`,
      // todo: update the properties depending on the back end setup
      // withCredentials: true,
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      // }
    })
    /** Create the api instance for public APIs **/
    this.apiPublic = Axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/api`,
      // todo: update the properties depending on the back end setup
      // withCredentials: true,
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      // }
    })
    this.source = null;
    this.store = null;
    this.apiCount = 0;
    this.isCookieDomain = false;
    this.router = null;
    this.api = LocalDataHandler.getAccessToken() ? this.apiAuth : this.apiPublic;
  }

  setAuthHandler(isAuth) {
    this.api = isAuth ? this.apiAuth : this.apiPublic;
  }

  setInterceptors(isCookieDomain = false) {
    this.isCookieDomain = isCookieDomain;
    const self = this;
    // Add a request interceptor
    this.apiAuth.interceptors.request.use(
      async function (config) {
        self.increaseApiCount();
        /** here update the access token with refresh token with native fetch() **/
        if (!LocalDataHandler.getAccessToken()) {
          await self.refreshToken();
          // after new tokens retrieved, don't forget to set it in the headers for following API calls
          config.headers = self.getAuthHeader();
          return config;
        } else {
          // Do something before request is sent
          return config;
        }
      },
      function (error) {
        self.decreaseApiCount();
        // Do something with request error
        return Promise.reject(error)
      }
    );
    // Add a response interceptor
    this.apiAuth.interceptors.response.use(
      function (response) {
        self.decreaseApiCount();
        // Do something with response data
        return response
      },
      function (error) {
        self.decreaseApiCount();
        // Do something with response error
        return Promise.reject(error)
      }
    );
    // Add a request interceptor for public APIs
    this.apiPublic.interceptors.request.use(
      function (config) {
        self.increaseApiCount();
        // Do something before request is sent
        return config
      },
      function (error) {
        self.decreaseApiCount();
        // Do something with request error
        return Promise.reject(error)
      }
    );
    // Add a response interceptor for public APIs
    this.apiPublic.interceptors.response.use(
      function (response) {
        self.decreaseApiCount();
        // Do something with response data
        return response
      },
      function (error) {
        self.decreaseApiCount();
        // Do something with response error
        return Promise.reject(error)
      }
    );
  }

  setRouter(router) {
    this.router = router;
  }

  increaseApiCount() {
    const store = useRootStore()
    this.apiCount++;
    if (this.apiCount > 0) {
      store.setLoading(true)
    }
  }

  decreaseApiCount() {
    const store = useRootStore()
    this.apiCount--;
    if (this.apiCount < 1) {
      store.setLoading(false)
    }
  }

  async refreshToken() {
    const refreshToken = LocalDataHandler.getRefreshToken();
    if (!refreshToken) {
      return this.router.push({name: ROUTE_NAMES.LOGOUT});
    }
    let formData = new FormData();
    formData.append('refresh_token', refreshToken);
    formData.append('grant_type', 'refresh_token');
    formData.append('client_id', process.env.VUE_APP_AUTH_CLIENT_ID);
    try {
      // use native fetch to get new tokens
      let response = await fetch(`${process.env.VUE_APP_API_URL}/api/auth/login`, {
        method: 'POST',
        withCredentials: true,
        body: new URLSearchParams(formData),
      });
      if (this.isSuccess(response.status)) {
        const data = await response.json();
        // set up in your cookies
        LocalDataHandler.setAccessToken(data.access_token, data.expireIn);
        LocalDataHandler.setRefreshToken(data.refresh_token, LocalDataHandler.VALUES.REFRESH_TOKEN_EXPIRE_TIME);
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
            /** tiny delay for database token saving **/
          }, TOKEN_SAVING_DELAY);
        })
      }
      console.error(response);
      return this.router.push({name: ROUTE_NAMES.LOGOUT});
    } catch (error) {
      console.error(error);
      return this.router.push({name: ROUTE_NAMES.LOGOUT});
    }
  }

  send(method, url, headers = {}, data = {}, params = {}, arrayBufferResponse = false) {
    return this.sendApi(this.api, method, url, headers, data, params, arrayBufferResponse);
  }

  /** you can also just use these 2 to force a public or auth api instance. don't forget to add new function like getPublic() **/
  sendPublic(method, url, headers = {}, data = {}, params = {}, arrayBufferResponse = false) {
    return this.sendApi(this.apiPublic, method, url, headers, data, params, arrayBufferResponse);
  }

  sendAuth(method, url, headers = {}, data = {}, params = {}, arrayBufferResponse = false) {
    return this.sendApi(this.apiAuth, method, url, headers, data, params, arrayBufferResponse);
  }

  sendApi(instance, method, url, headers = {}, data = {}, params = {}, arrayBufferResponse = false) {
    this.source = Axios.CancelToken.source();
    // Define the headers if they are undefined
    headers = headers ? headers : {}
    return instance.request({
      method: method,
      url: url,
      headers: headers !== undefined ? headers : {},
      data: data !== undefined ? data : {},
      params: params !== undefined ? params : {},
      responseType: arrayBufferResponse === true ? 'arraybuffer' : undefined,
      cancelToken: this.source.token
    })
  }

  get(url, headers = {}, params = {}, responseType = false) {
    return this.send('GET', url, headers, undefined, params, responseType)
  }

  post(url, data = {}, headers = {}, params = {}, fileResponse = {}) {
    return this.send('POST', url, headers, data, params, fileResponse)
  }

  patch(url, data = {}, headers = {}, params = {}) {
    return this.send('PATCH', url, headers, data, params)
  }

  put(url, data = {}, headers = {}, params = {}) {
    return this.send('PUT', url, headers, data, params)
  }

  delete(url, data = {}, header = {}) {
    return this.send('DELETE', url, header, data)
  }

  cancelRequest() {
    this.source.cancel('Request Cancelled.')
  }

  getAuthHeader() {
    // Check if access token is present
    if (!LocalDataHandler.getAccessToken()) return null
    return {
      Authorization: `Bearer ${LocalDataHandler.getAccessToken()}`,
      'accept-language': 'en'
    }
  }

  isSuccess(statusCode) {
    return (
      statusCode === RESPONSE_TYPES.STATUS_OK ||
      statusCode === RESPONSE_TYPES.STATUS_CREATED ||
      statusCode === RESPONSE_TYPES.STATUS_NO_CONTENT
    )
  }

  parseFormData(data) {
    let formData = new FormData()
    // get the first level of data
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          // here data[key] is an array, we need both index and value below
          for (const [index, value] of data[key].entries()) {
            // the value is an object, so loop through each key
            for (const childKey in value) {
              if (value.hasOwnProperty(childKey)) {
                // parse the data to correct format for array of objects
                formData.append(`${key}[${index}].${childKey}`, value[childKey])
              }
            }
          }
        } else {
          formData.append(key, data[key])
        }
      }
    }
    return formData
  }

  parseNonArrayFormData(data) {
    let formData = new FormData()
    // get the first level of data
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (!Array.isArray(data[key])) {
          formData.append(key, data[key])
        }
      }
    }
    return formData
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ApiHandler()
    }
    return this.instance
  }
}

export default ApiHandler.getInstance()
