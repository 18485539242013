import LocalDataHandler from '@/utils/handlers/LocalDataHandler';
import apiHandler from '@/utils/handlers/ApiHandler';
import {useAuthStore} from '@/store/auth'

export const apiActions = {
  sendLogin(data) {
    const store = useAuthStore()
    // Create the form data:
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', process.env.VUE_APP_AUTH_CLIENT_ID);
    formData.append('username', data.username);
    formData.append('password', data.password);
    if (data.verificationCode) {
      formData.append('totp_code', data.verificationCode);
    }
    return apiHandler.post('auth/login', formData).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        store.loginSuccess(Object.assign(data, response.data));
        // apiHandler.setAuthHandler(true);
        LocalDataHandler.setAuthenticated();
        return true;
      }
      return false;
    })
    // Catch is handled in Login.vue
  },

  getLoggedInUser() {
    const store = useAuthStore()
    return apiHandler.get(`user/profile`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        store.setLoggedInUser(response.data);
        return true;
      }
      return false;
    }).catch(error => {
      console.error(error);
      return false;
    });
  },

  activateUser(data) {
    const rawData = {
      password: data.password,
      activationToken: data.activationToken
    }
    return apiHandler.post(`auth/activate-professional/${data.userId}`, rawData).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        LocalDataHandler.setAuthenticated();
        return response.data;
      }
      return false;
    })
  },

  forgotPassword(email) {
    const store = useAuthStore()
    return apiHandler.post(`auth/forgot-password`, email).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        LocalDataHandler.setAuthenticated();
        return true;
      }
      return false;
    }).catch((error) => {
      store.setAuthError(error);
    })
  },

  resetPassword(data) {
    const rawData = {
      password: data.password
    }
    const store = useAuthStore()
    return apiHandler.post(`auth/reset-password/${data.userId}/${data.resetToken}`, rawData).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        LocalDataHandler.setAuthenticated();
        return true;
      }
      return false;
    }).catch((error) => {
      store.setAuthError(error);
    })
  }
}
