<template>
  <div class="not-found">
    The page you entered is not found.
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
  }
</script>

<style scoped lang="scss">
  @import "~@/assets/css/_base.variables";
  @import "~@/assets/css/_base.mixins";

  .not-found {
    padding: rem(40);
  }
</style>
